import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { ConfirmComponent } from "../../helpers/dialog/confirm/confirm.component";
import { InfoComponent } from "../../helpers/dialog/info/info.component";

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) { }

  public showMessage(message: string, title? : string): MatDialogRef<InfoComponent>{
    return this.dialog
    .open(InfoComponent, {
      width: '500px',
      data: {
        message: message,
        title: title || 'Message'
      }
    })
  }

  public showConfirm(message: string, title?: string, acceptMessage?: string, closeMessage?: string): MatDialogRef<ConfirmComponent>{
    return this.dialog
    .open(ConfirmComponent, {
      width: '500px',
      data: {
        message: message,
        accept: acceptMessage,
        decline: closeMessage,
        title: title || 'Message'
      }
    })
  }

  public showSnackBar(message: string): MatSnackBarRef<SimpleSnackBar>{
    const snackBar = this.snackBar.open(message, null, {
      duration: 2000
    })
    return  snackBar;
  }
}
