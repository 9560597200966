import { Injectable } from '@angular/core';
import { RequestService } from "../request/request.service";
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment'
import { zip, groupBy, orderBy } from "lodash";
import { Alert, Reminder } from "../../interfaces/interfaces.index";
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class SupervisorService {
  private numberPipe: DecimalPipe;
  constructor(private requestService: RequestService) { 
    this.numberPipe = new DecimalPipe('en-US');
  }

  // ************************************************************************
  // **********************     ASSETS TABLE     ****************************
  // ************************************************************************
  public getTubingAndCasing(wellId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/associatedWellObjects/associatedWellObjects', {
          assetId: [wellId]
        })
        if(response.statusCode === 200){
          resolve({
            tubing: this.getValueFromAssociatedObject(response.data || [], 'Tubing pressure', ['psi']),
            casing: this.getValueFromAssociatedObject(response.data || [], 'Casing pressure', ['psi'])
          });
        }else{
          reject({ message: response.message });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getWaterAndOil(tankId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/associatedWellObjects/associatedWellObjects', {
          assetId: [tankId]
        })
        // console.log('Response Table: ', response);
        if(response.statusCode === 200){
          resolve({
            // oil: this.getValueFromAssociatedObject(response.data || [], 'Oil tank level', ['in', 'ft']),
            // water: this.getValueFromAssociatedObject(response.data || [], 'Water tank level', ['in', 'ft'])
            level: this.getLevelFromTank(response.data || [], 'Tank level')
          });
        }else{
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public getValueFromAssociatedObject(data: any, type: string, units: string[]): any{
    const finalItem = data
    .filter(item => item.id.includes('DATAPOINTDATA') && item.sensortype === type)
    .map(item => {
      item.date = moment(`${item.date}T${item.time}`).toDate();
      return item;
    })
    .reduce((value, item)=> {
      if(!value){
        return item;
      }else if(value.date < item.date){
        return item;
      }else{
        return value
      }
    }, null);
    if(!finalItem) return 0;
    return finalItem.values
    .map((value, index)=> {
      return `${this.numberPipe.transform(value, '1.2-2')} ${units[index] || units[0]}`;
    })
    .join(', ');
  }

  public getLevelFromTank(data: any, type: string): any{
    // console.log('Data Level: ', data);
    const finalItem = data
    .filter(item => item.id.includes('DATAPOINTDATA') && item.sensortype === type)
    .map(item => {
      item.date = moment(`${item.date}T${item.time}`).toDate();
      return item;
    })

    let order = orderBy(finalItem, ['udatetime'], ['asc'])
    // console.log('Order: ', order);

    let lastItem = null;
    if(order.length > 0){
      lastItem = order[order.length - 1];
    }else{
      lastItem = {
        values: ["0"]
      }
    }
    // console.log(lastItem);
    // .reduce((value, item)=> {
    //   console.log('Value: ', value, 'Item: ', item);
    //   if(!value){
    //     return item;
    //   }else if(value.date < item.date){
    //     return item;
    //   }else{
    //     return value
    //   }
    // }, null);
    // if(!finalItem) return 0;
    // console.log('Final Item: ', parseFloat(finalItem.values[0]));
    return parseFloat(lastItem.values[0])
  }

  // ************************************************************************
  // *************************      CHARTS     ******************************
  // ************************************************************************
  public getPressureChart(wellId: string, dataPoints: string[], startDate: string, endDate: string): Promise<any[]> {
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/points/wellHeadPressure', {
          asset: wellId,
          endDate: endDate,
          startDate: startDate,
          datapointinfo: dataPoints
        });
        // console.log('Pressure chart: ', response);
        if (response.statusCode === 200) {
          // resolve(this.formatData(response.Chart || []));
          // resolve(this.averagePressureData(response.Chart || []));
          resolve(this.clusterPressureData(response.Chart || [], startDate, endDate));
        } else {
          reject({ message: response.message });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getSmoothPressureChart(wellId: string, dataPoints: string[], startDate: string, endDate: string): Promise<any[]>{
    return new Promise(async (resolve, reject)=> {
      try{
        const response = await this.requestService.post('/points/wellHeadPressure', {
          asset: wellId,
          endDate: endDate,
          startDate: startDate,
          datapointinfo: dataPoints
        });
        // console.log('Data: ', response);

        if(response.statusCode === 200){
          resolve(this.smoothPressureChart(response.Chart, startDate, endDate));
        }else{
          reject({message: response.message})
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public smoothPressureChart(data: Array<any>, startDate: string, endDate: string): Array<any>{
    // console.log('DATA: ', data);
    
    let series = [];
    
    let clusterData = this.clusterPressureData(data, startDate, endDate);
    let smoothData = this.smoothPressureData(data, startDate, endDate);

    // console.log('Cluster: ', clusterData);
    // console.log('Smooth: ', smoothData);

    series.push(clusterData);
    series.push(smoothData);

    return series;
  }

  public getTemperatureChart(wellId: string, dataPoints: string[], startDate: string, endDate: string): Promise<any[]>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/getPointsAndInfo/getTempreatureChart', {
          asset: wellId,
          endDate: endDate,
          startDate: startDate,
          datapointinfo: dataPoints
        });
        if (response.statusCode === 200) {
          resolve(this.formatData(response.Chart || []));
        } else {
          reject({ message: response.message });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getOilChart(tankId: string, dataPoints: string[], startDate: string, endDate: string): Promise<any[]>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/getPointsAndInfo/getTankChartoil', {
          asset: tankId,
          endDate: endDate,
          startDate: startDate,
          datapointinfo: dataPoints
        });
        if(response.statusCode === 200){
          resolve(this.formatData(response.Chart || []));
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getWaterChart(tankId: string, dataPoints: string[], startDate: string, endDate: string): Promise<any[]>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/points/waterTank', {
          asset: tankId,
          endDate: endDate,
          startDate: startDate,
          datapointinfo: dataPoints
        });
        // console.log('Response Chart chart: ', response);
        if(response.statusCode === 200){
          // resolve(this.formatData(response.Chart || []));
          // resolve(this.getWaterValues(response.Chart || []));
          // resolve(this.formatTankValues(response.Chart || []));
          // resolve(this.strictFormatTankValues(response.Chart, startDate, endDate));
          resolve(this.getTankValuesWithUnits(response.Chart, startDate, endDate));
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  getTankValuesWithUnits(data: Array<any>, startDate: string, endDate: string): Array<any>{
    let series = [];

    let inches = this.strictFormatTankValues(data, startDate, endDate);
    let feet = this.feetTankValues(data, startDate, endDate);
    let meters = this.metersTanksValues(data, startDate, endDate);

    series.push(inches);
    series.push(feet);
    series.push(meters);

    // console.log(series);

    return series;
  }

  metersTanksValues(data: any[], startDate, endDate):any{
    let series = [];
    let compoundStartDate = `${startDate}T05:00:00.491633Z`;
    var unixTimestamp = Date.parse(moment.utc(compoundStartDate).local().format());

    let date1 = `${startDate} 05:00:00.491633Z`
    let date1x = Date.parse(moment.utc(date1.replace(' ', 'T')).local().format());
    let date2 = `${endDate} 05:00:00.491633Z`
    let date2x = Date.parse(moment.utc(date2.replace(' ', 'T')).local().format());

    data.forEach(element => {
      
      if(element.datapointInfo.name === 'Tank level'){
        let values = element.value.map(value =>  parseFloat(value)*0.0254 );
        let dates = element.dateTime.map(value => Date.parse(moment.utc(value).local().format()));
        let str = element.datapointInfo.name as string;
        let measurement = element.datapointInfo.assetmeasurement as string;
        let filterDates = [];
        let newValues = [];
        for(let i in dates){
          if(unixTimestamp-dates[i] <= 0){
            filterDates.push(dates[i]);
            if(values[i]<0){
              newValues.push(0)
            }else{
              newValues.push(values[i])
            }
          }
        }

        let noDates = [];
        let noValues = [];
        for(const i in newValues){
          if(newValues[i] != 0){
            noValues.push(newValues[i]);
            noDates.push(filterDates[i]);
          }
        }
        
        const serie = {
          data: zip(noDates, noValues),
          name: str,
          assetmeasurement: measurement
        }
        series.push(serie);

        for( const i in series){
          if(series[i].data[0]){
            let first = series[i].data[0];
            if(date1x <= first[0]){
              series[i].data.unshift([date1x, null])
            }
          }
          if(series[i].data[series[i].data.length-1]){
            let last = series[i].data[series[i].data.length-1];
            if(date2x >= last[0]){
              series[i].data.push([date2x, null])
            }
          }
        }
      }
    })

    let finalSeries = [];
    if(series.length > 0 ){
      finalSeries.push(series[0])
    }else {
      finalSeries = series;
    }
    return finalSeries;
  }

  feetTankValues(data: any[], startDate, endDate):any{
    let series = [];
    let compoundStartDate = `${startDate}T05:00:00.491633Z`;
    var unixTimestamp = Date.parse(moment.utc(compoundStartDate).local().format());

    let date1 = `${startDate} 05:00:00.491633Z`
    let date1x = Date.parse(moment.utc(date1.replace(' ', 'T')).local().format());
    let date2 = `${endDate} 05:00:00.491633Z`
    let date2x = Date.parse(moment.utc(date2.replace(' ', 'T')).local().format());

    data.forEach(element => {
      
      if(element.datapointInfo.name === 'Tank level'){
        // let values = element.value.map(value =>  parseFloat(`${(Math.floor(value/12))}.${value-(12*Math.floor(value/12))}`) );
        let values = element.value.map(value => value/12);
        // console.log(values);
        let dates = element.dateTime.map(value => Date.parse(moment.utc(value).local().format()));
        let str = element.datapointInfo.name as string;
        let measurement = element.datapointInfo.assetmeasurement as string;
        let filterDates = [];
        let newValues = [];
        for(let i in dates){
          if(unixTimestamp-dates[i] <= 0){
            filterDates.push(dates[i]);
            if(values[i]<0){
              newValues.push(0)
            }else{
              newValues.push(values[i])
            }
          }
        }
        
        let noDates = [];
        let noValues = [];
        for(const i in newValues){
          if(newValues[i] != 0){
            noValues.push(newValues[i]);
            noDates.push(filterDates[i]);
          }
        }

        const serie = {
          data: zip(noDates, noValues),
          name: str,
          assetmeasurement: measurement
        }
        series.push(serie);

        for( const i in series){
          if(series[i].data[0]){
            let first = series[i].data[0];
            if(date1x <= first[0]){
              series[i].data.unshift([date1x, null])
            }
          }
          if(series[i].data[series[i].data.length-1]){
            let last = series[i].data[series[i].data.length-1];
            if(date2x >= last[0]){
              series[i].data.push([date2x, null])
            }
          }
        }
      }
    })

    let finalSeries = [];
    if(series.length > 0 ){
      finalSeries.push(series[0])
    }else {
      finalSeries = series;
    }
    return finalSeries;
  }


  strictFormatTankValues(data: any[], startDate, endDate): any{
    let series = [];
    // console.log('Start Date: ', startDate);
    // let compoundStartDate = `${startDate}T00:00:00.491633Z`;
    let compoundStartDate = `${startDate}T05:00:00.491633Z`;
    // console.log('C Start Date: ', compoundStartDate);
    // var unixTimestamp = moment(compoundStartDate, 'YYYY-MM-DD HH:mm:ss').unix();
    var unixTimestamp = Date.parse(moment.utc(compoundStartDate).local().format());
    // console.log('Start Date 2: ', unixTimestamp);
    

    let date1 = `${startDate} 05:00:00.491633Z`
    let date1x = Date.parse(moment.utc(date1.replace(' ', 'T')).local().format());

    let date2 = `${endDate} 05:00:00.491633Z`
    let date2x = Date.parse(moment.utc(date2.replace(' ', 'T')).local().format());


    data.forEach(element => {
      if(element.datapointInfo.name === 'Tank level'){
        // console.log('Element: ', element);
        let values = element.value.map(value => parseFloat(value));
        // console.log(values);
        // let dates = element.dateTime.map(value => moment.utc(value, 'YYYY-MM-DD HH:mm:ss').unix());
        let dates = element.dateTime.map(value => Date.parse(moment.utc(value).local().format()));
        let str = element.datapointInfo.name as string;
        let measurement = element.datapointInfo.assetmeasurement as string;
        // console.log('Dates: ', dates);
        // console.log('Values: ', values);
        let filterDates = [];
        let newValues = [];
        for(let i in dates){
          if(unixTimestamp-dates[i] <= 0){
            filterDates.push(dates[i]);
            if(values[i]<0){
              newValues.push(0)
            }else{
              newValues.push(values[i])
            }
          }
        }
        // console.log('Filter dates: ', filterDates);
        // console.log('New values: ', newValues);

        let noDates = [];
        let noValues = [];
        for(const i in newValues){
          if(newValues[i] != 0){
            noValues.push(newValues[i]);
            noDates.push(filterDates[i]);
          }
        }
        // console.log('NO ZERO DATES: ', noDates);
        // console.log('NO ZERO VALUES: ', noValues);

        const serie = {
          data: zip(noDates, noValues),
          name: str,
          assetmeasurement: measurement
        }
        series.push(serie);
        // console.log('Series: ', series);
        for( const i in series){
          if(series[i].data[0]){
            let first = series[i].data[0];
            if(date1x <= first[0]){
              series[i].data.unshift([date1x, null])
            }
          }
          if(series[i].data[series[i].data.length-1]){
            let last = series[i].data[series[i].data.length-1];
            if(date2x >= last[0]){
              series[i].data.push([date2x, null])
            }
          }
        }
      }
    })

    // console.log('Service Series: ', series);
    
    let finalSeries = [];
    if(series.length > 0 ){
      finalSeries.push(series[0])
    }else {
      finalSeries = series;
    }
    
    // console.log('Final series: ', finalSeries);

    return finalSeries;
  }

  public formatTankValues(data: any[]): any[]{
    let series = [];
    data.forEach(element => {
      // console.log('Element: ', element);
      if(element.datapointInfo.name === 'Tank level'){
        element.value = element.value.map(value => parseFloat(value));
        // console.log('Datetime0: ', element.dateTime);
        element.dateTime = element.dateTime.map(value => Date.parse(moment.utc(value).local().format())); 
        let str = element.datapointInfo.name as string;
        const serie = {
          data: zip(element.dateTime, element.value),
          name: str,
        }
        // console.log('Serie: ', serie);
        series.push(serie);
        }
    })
    return series;
  }

  public getWaterValues(data: any[]): any[]{
    let series = [];
    data.forEach(element => {
      if(element.datapointInfo.name === 'Tank level'){
        let str = element.datapointInfo.name as string;
        let datetime = element.dateTime;
        datetime = datetime.map(value => value.split(' '));
        element.value = element.value.map(value => parseFloat(value));
        let obj = [];
        for(const i in datetime){
          let serie = {
            day: datetime[i][0],
            hour: datetime[i][1],
            value: element.value[i]
          }
          obj.push(serie);
        }
        let example = groupBy(obj, function(a): any{
          return a.day
        })
        let dates = [];
        let values = [];
        for (const i in example){
          let total = 0;
          let average = 0;
          for(const j in example[i]){
            total += example[i][j].value;
          }
          average = total / example[i].length;
          average = parseFloat(average.toFixed(2));

          let date = `${i} 00:00:00.491633Z`;
          let datex = Date.parse(date.replace(' ', 'T'));
          dates.push(datex);
          values.push(average);
        }
        let serie = {
          data: zip(dates, values),
          name: str
        };
        series.push(serie);
      }
    })
    return series;
  }

  public clusterPressureData(data: Array<any>, startDate: string, endDate: string): Array<any>{
    let series = [];
    
    // console.log('Start date: ', startDate);
    let date1 = `${startDate} 05:00:00.491633Z`
    let date1x = Date.parse(moment.utc(date1.replace(' ', 'T')).local().format());
    // console.log('Start date U: ', date1x);

    // console.log('End date: ', endDate);
    let date2 = `${endDate} 05:00:00.491633Z`
    let date2x = Date.parse(moment.utc(date2.replace(' ', 'T')).local().format());
    // console.log('End date U: ', date2x);


    data.forEach(element => {
      // console.log('Element: ', element);
      let str = element.datapointInfo.name as string;
      let valoresStr = str.split(' ');
      let nameStr = valoresStr[0];
      // let measurement = element.datapointInfo.assetmeasurement  as string;
      let datetime = element.dateTime;
      datetime = datetime.map(value => value.split(/[\s:]+/))
      // console.log('Date time: ', datetime);


      // for(const i in datetime){
      //   console.log('D: ', datetime[i][0]);
      // }

      element.value = element.value.map(value => parseFloat(value));
      let obj = [];
      for(const i in datetime){
        let serie = {
          day: datetime[i][0],
          hour: datetime[i][1],
          time: `${datetime[i][1]}:${datetime[i][2]}:${datetime[i][3]}`,
          value: element.value[i]
        }
        obj.push(serie);
      }
      // console.log('OBJ: ', obj);
      let example = groupBy(obj, function(a): any{
        return `${a.day} ${a.hour}`
      })
      // console.log('Example: ', example);
      let dates = [];
      let values = [];
      for(const i in example){
        let total = 0;
        let average = 0;
        for(const j in example[i]){
          total += example[i][j].value;
        }
        average = total/example[i].length;
        average = parseFloat(average.toFixed(2));

        let date = `${i}:00:00.491633Z`;
        // console.log('Date: ', date);
        let datex = Date.parse(moment.utc(date.replace(' ', 'T')).local().format());
        // let datex = Date.parse(date.replace(' ', 'T'));
        dates.push(datex);
        values.push(average);
      }
      let serie = {
        data: zip(dates, values),
        name: nameStr,
        id: 'cluster'
      };
      series.push(serie);


      // console.log('Series 1: ', series);
      for( const i in series){
        if(series[i].data[0]){
          let first = series[i].data[0];
          if(date1x <= first[0]){
            series[i].data.unshift([date1x, null])
          }
        }
        if(series[i].data[series[i].data.length-1]){
          let last = series[i].data[series[i].data.length-1];
          if(date2x >= last[0]){
            series[i].data.push([date2x, null])
          }
        }
      }
    })
    return series;
  }

  public smoothPressureData(data: any[], startDate: string, endDate: string): any[]{
    // console.log('Data: ', data);
    let series = [];
    let date1 = `${startDate} 05:00:00.491633Z`
    let date1x = Date.parse(moment.utc(date1.replace(' ', 'T')).local().format());
    // console.log('Start date U: ', date1x);

    // console.log('End date: ', endDate);
    let date2 = `${endDate} 05:00:00.491633Z`
    let date2x = Date.parse(moment.utc(date2.replace(' ', 'T')).local().format());
    
    // console.log('Info: ', data);
    data.forEach(info=> {
      info.value = info.value.map(value => parseFloat(value));
      // element.dateTime = element.dateTime.map(value => Date.parse(moment.utc(value).local().format()));
      // info.dateTime = info.dateTime.map(value => +new Date(value));
      info.dateTime = info.dateTime.map(value => Date.parse(moment.utc(value).local().format()));
      var str = info.datapointInfo.name as string;
      var valoresStr = str.split(' ');
      var nameStr = valoresStr[0];
      let serie = {
        data: zip(info.dateTime, info.value),
        name: nameStr,
        id: 'raw'
      }
      series.push(serie);
      // console.log('Series: ', series);
      for( const i in series){
        if(series[i].data[0]){
          let first = series[i].data[0];
          if(date1x <= first[0]){
            series[i].data.unshift([date1x, null])
          }
        }
        if(series[i].data[series[i].data.length-1]){
          let last = series[i].data[series[i].data.length-1];
          if(date2x >= last[0]){
            series[i].data.push([date2x, null])
          }
        }
      }
    })
    return series;
  }

  public averagePressureData(data: Array<any>): Array<any>{
    let series = [];
    // console.log('Data: ', data);
    data.forEach(element => {
      let str = element.datapointInfo.name as string;
      let valoresStr = str.split(' ');
      let nameStr = valoresStr[0];
      let datetime = element.dateTime;
      datetime = datetime.map(value => value.split(' '));
      element.value = element.value.map(value => parseFloat(value));
      let obj = [];
      for(const i in datetime){
        let serie = {
          day: datetime[i][0],
          hour: datetime[i][1],
          value: element.value[i]
        }
        obj.push(serie);
      }
      console.log('OBJ:', obj);
      let example = groupBy(obj, function(a): any{
        return a.day
      })
      console.log('Example: ', example);
      let dates = [];
      let values = [];
      for (const i in example){
        let total = 0;
        let average = 0;
        for(const j in example[i]){
          total += example[i][j].value;
        }
        average = total / example[i].length;
        average = parseFloat(average.toFixed(2));

        let date = `${i} 00:00:00.491633Z`;
        let datex = Date.parse(date.replace(' ', 'T'));
        dates.push(datex);
        values.push(average);
      }
      let serie = {
        data: zip(dates, values),
        name: nameStr
      };
      series.push(serie);

    })
    return series;
  }

  public formatData(data: any[]): any[]{
    const series = [];
    data.forEach(info=> {
      info.value = info.value.map(value => parseFloat(value));
      info.dateTime = info.dateTime.map(value => +new Date(value));
      var str = info.datapointInfo.name as string;
      var valoresStr = str.split(' ');
      var nameStr = valoresStr[0];
      const serie = {
        data: zip(info.dateTime, info.value),
        name: nameStr,
      }
      series.push(serie);
    })
    return series;
  }

  public getPressureDiagnosis(wellId: string, date: string){
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/getDataPoint/getDiagnosispressure', {
          asset: wellId,
          date: date
        });
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  // ************************************************************************
  // ************************     DYNACARD     ******************************
  // ************************************************************************
  public getDynacardChart(wellId: string, startDate: string): Promise<any[]>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/dynacard/getDynacardPoints', {
          asset: wellId,
          date: startDate
        })
        // console.log('Response: ', response);
        if (response.statusCode === 200) {

          if(response.data.hasOwnProperty('dateTime')){
            const series = response.data.dateTime.map((time, index) => {
              let xAxisIndex = index;
              if (index === 1) {
                xAxisIndex += 1;
              } else if (index >= 2) {
                xAxisIndex *= 2;
              }
              return {
                name: response.data.dateTime[index],
                data: zip(response.data.axis[xAxisIndex], response.data.axis[xAxisIndex + 1])
              };
            });
            // console.log('Series: ', series);
            resolve(series);
          }else{
            resolve([]);
          }
        } else {
          reject({ message: response.message });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getDynacardDiagnosis(wellId: string, startDate: string): Promise<any[]>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/getDynacard/getDiagnosisReport', {
          asset: wellId,
          date: startDate
        })
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getDynacardObject(wellId: string, date: string){
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/dynacard/getDiagnosisReportObject', {
          asset: wellId,
          date: date
        })
        // console.log('Response: ', response);
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  // ************************************************************************
  // ***************************     DATES     ******************************
  // ************************************************************************
  public getDatesPressure(wellId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        // const response = await this.requestService.post('/findstartenddate/findstartenddate', {
        const response = await this.requestService.post('/dates/finddatesforDatapointdatas', {
          assetId: wellId
        });
        if (response.statusCode === 200) {
          resolve(response);
        } else {
          reject({ message: response.message });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getDatesDynacard(wellId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        // const response = await this.requestService.post('/findstartenddate/finddateforDynacard', {
        const response = await this.requestService.post('/dates/finddatesforDyncards', {
          assetId: wellId
        });
        if (response.statusCode === 200) {
          resolve(response);
        } else {
          reject({ message: response.message });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getDatesDynacardReport(wellId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        // const response = await this.requestService.post('/findstartenddate/finddatefordreport', {
        const response = await this.requestService.post('/dates/finddatesforReports', {
          assetId: wellId
        });
        if(response.statusCode === 200){
          resolve(response)
        }else{
          reject({message: response. message});
        }
      }catch(err){
        reject(err);
      }
    })
  }


  // ************************************************************************
  // ********************     ALERTS & REMINDERS     ************************
  // ************************************************************************
  public getAlerts(wellsId: string[]): Promise<{ [key: string]: Alert[] }> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.requestService.post('/alert/getAlert', {
          assetId: wellsId
        });
        if (response.statusCode === 200) {
          resolve(groupBy(response.data, 'asset'));
        } else {
          reject({ message: response.message });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  public addAlert(alert: any): Promise<any>{
    return new Promise(async(resolve, reject) => {
      try{
        const response = await this.requestService.post('/alert/createAlert', alert);
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public getReminders(wellsId: string[]): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createReminder/getReminder', {
          assetId: wellsId
        });
        if(response.statusCode === 200) {
          resolve(response.data);
        }else {
          reject({message : response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public addReminder(reminder: Reminder): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.requestService.post('/createReminder/createReminder', reminder);
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  public acknowledgeAlert(person, owner, lastupdatedby, object, coordinates): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/acknowledge/createAcknowledge', { 
          person: person,
          owner: owner,
          lastupdatedby: lastupdatedby,
          object: object,
          coordinates: coordinates
        });
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  // ************************************************************************
  // ********************     ALERTS & REMINDERS     ************************
  // ************************************************************************
  public saveAlertTrigger(alertTrigger: any): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createalerttrigger/createalerttrigger', alertTrigger);
        // console.log('Alert Response :', response);
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  // ************************************************************************
  // ***************************     PUMPER     *****************************
  // ************************************************************************
  public getPumper(wellId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/findpumper/assets', {
          wellid: wellId
        })
        if(response.statusCode === 200) {
          resolve(response.data);
        }else {
          reject({message : response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getPumperReport(wellsId: string[]): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/pumperReport/getReport', {
          assetId: wellsId
        });
        if(response.statusCode === 200) {
          resolve(response.data);
        }else {
          reject({message : response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getPumperNotes(pumperId: string[]): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/PumperNotesApp/getpumpernotes', {
          pumpernotesId: pumperId
        });
        if(response.statusCode === 200) {
          resolve(response.data);
        }else {
          reject({message : response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getPictureIds(pumperNotesId: string): Promise<any>{
    return new Promise(async(reject, resolve)=> {
      try{
        const response = await this.requestService.post('/PumperNotesApp/getpictureIds', {
          pumpernotesId: pumperNotesId
        });
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getPicture(pictureId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/storePumperImage/getpumperImage', {
          imageId: [pictureId]
        });
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  // ************************************************************************
  // ********************     UNITS & DATAPOINTS     ************************
  // ************************************************************************
  public getUnits(): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/datapointinfo/listunits');
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public getDatapoinstInfo(): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/datapointinfo/listdatapointinfo');
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public getAsset(assetId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addWell/getWell', {
          wellid : assetId  
        });
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  // ************************************************************************
  // **********************     ALERTS TRIGGERS     *************************
  // ************************************************************************
  
  public getAlertTrigger(test: string): Promise<any>{
    return new Promise(async(resolve, reject) => {
      try{
        const response = await this.requestService.post('/createalerttrigger/getalerttrigger', {
          name: test
        });
        // console.log('Response: ', response);
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public deleteAlertTrigger(alertId: string): Promise<any>{
    return new Promise(async(resolve, reject) => {
      try{
        const response = await this.requestService.post('/createalerttrigger/deletealerttrigger', {
          alerttriggerid : alertId
        })

        // console.log('Response: ', response);
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public editAlertTrigger(alertTrigger: any): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createalerttrigger/editalerttrigger', alertTrigger);
        // console.log('Alert Response :', response);
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response.message});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public downloadData(downloadObj: any): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/downloader/download-data', downloadObj);
        // console.log('Response DD: ', response);
        if(response.statusCode === 200){
          resolve(response.body);
        }else{
          reject({message: response});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public sendVcard(usersObj: any): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/alert-handler/vcard', usersObj);
        if(response.statusCode === 200){
          resolve(response.body);
        }else{
          reject({message: response});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public assetMeasurements(): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/alert-measurements/list-measurements');
        // console.log(response);
        if(response.statusCode === 200){
          resolve(response.body);
        }else{
          reject({message: response});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  // ************************************************************************
  // ***********************     LATEST VALUES      *************************
  // ************************************************************************
  public getLatestValues(companyId: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      try{
        const response = await this.requestService.post('/values/latest', {
          companyId: companyId 
        });
        // console.log(response);
        if(response.statusCode === 200){
          resolve(response.body);
        }else{
          reject({message: response});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public getGatewayInfo(assetId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/hwadmin/hwinfo', {
          assetId: assetId
        });
        // console.log('Gateway Info: ', response);
        if(response.statusCode === 200){
          resolve(response.body);
        }else{
          reject({message: response});
        }
      }catch(err){
        reject(err);
      }
    })
  }

  // ************************************************************************
  // ***********************     GAS LUG REPORT     *************************
  // ************************************************************************

  public getGasLugReport(assetId: string, date: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/report/getgaslug', {
          date: date,
          asset: assetId
        })
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message: response});
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public getGasWeekReport(assetId: string, date: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try {
        const response = await this.requestService.post('/gaslug/gaslugweek', {
          endDate: date,
          asset: assetId
        })
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message : response})
        }
      }
      catch(err){
        reject(err)
      }
    })
  }

}
