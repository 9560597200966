import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { CompanyService } from "../company/company.service";
import { SupervisorService } from '../supervisor/supervisor.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  initialLatestValuesChart: any[];
  constructor(public companyService: CompanyService, public supervisorService: SupervisorService) { 
    this.getUnits();
    this.getDatapoints();
    this.getAlertTriggers();
    this.getAssetMeasurements();
  }

  private readonly _assets = new BehaviorSubject<any>({});
  readonly assets$ = this._assets.asObservable();

  private readonly _users = new BehaviorSubject<any>([]);
  readonly users$ = this._users.asObservable();

  private readonly _units = new BehaviorSubject<any>([]);
  readonly units$ = this._units.asObservable();

  private readonly _datapoints = new BehaviorSubject<any>([]);
  readonly datapoints$ = this._datapoints.asObservable();

  private readonly _alertTriggers = new BehaviorSubject<any>([]);
  readonly alertTriggers$ = this._alertTriggers.asObservable();

  private readonly _assetMeasurements = new BehaviorSubject<any>([]);
  readonly assetMeasurements$ = this._assetMeasurements.asObservable();

  private readonly _latestValues = new BehaviorSubject<any>([]);
  readonly latestValues$ = this._latestValues.asObservable();

  private readonly _latestValuesChart = new BehaviorSubject<any>([]);
  readonly latestValuesChart$ = this._latestValuesChart.asObservable();

  get assets(): any{
    return this._assets.getValue();
  }

  set assets(val: any){
    this._assets.next(val);
  }

  get users(): any{
    return this._users.getValue();
  }

  set users(value: any){
    this._users.next(value);
  }

  get units(): any{
    return this._units.getValue();
  }

  set units(value: any){
    this._units.next(value);
  }

  get datapoints():any{
    return this._datapoints.getValue();
  }

  set datapoints(value: any){
    this._datapoints.next(value);
  }

  get alertTriggers(): any{
    return this._alertTriggers.getValue();
  }

  set alertTriggers(value: any){
    this._alertTriggers.next(value);
  }

  get assetMeasurements(): any{
    return this._assetMeasurements.getValue()
  }

  set assetMeasurements(value: any){
    this._assetMeasurements.next(value);
  }

  get latestValues(): any{
    return this._latestValues.getValue()
  }

  set latestValues(value: any){
    this._latestValues.next(value);
  }

  get latestValuesChart():any{
    return this._latestValuesChart.getValue();
  }

  set latestValuesChart(value: any){
    this._latestValuesChart.next(value);
  }

  getLatestValues(companyID: string){
    let latest = this.supervisorService.getLatestValues(companyID);
    latest
    .then(response => {
      
      // console.log('Response: ', response)

      // let values = JSON.parse(response)
      this.latestValues = response.latestValues;
      // console.log('Latest values: ', this.latestValues);
    })
    .catch(err => {
      console.log('Error getting latest values: ', err);
    })
  }

  getAssets(companyID: string){
    let wells = this.companyService.allWells(companyID);
    let tanks = this.companyService.allTanks(companyID);
    let locations = this.companyService.allLocations(companyID);
    Promise.all([wells, tanks, locations])
    .then(assets => {
      // console.log('Assets: ', assets);
      let allAssets = {
        wells: assets[0],
        tanks: assets[1],
        tankBatteries: assets[2]
      }
      this.assets = allAssets;
    })
    .catch(err => {
      this.assets = {};
      console.log('Error getting assets: ', err)
    })
  }

  getUsers(companyID: string){
    let users = this.companyService.getUsersByCompany(companyID);
    users
    .then(users => {
      this.users = users;
      // console.log('Users 2: ', users);
    })
    .catch(err => {
      console.log('Error getting users: ', err);
    })
  }

  getAlertTriggers(){
    let atriggers = this.supervisorService.getAlertTrigger('');
    atriggers
    .then(atriggers => {
      let triggers: any[] = atriggers;
      if(triggers.length > 0){
        this.alertTriggers = atriggers;
      }
      else{
        this.alertTriggers = [{
          empty: true
        }]
      } 
    })
    .catch(err => {
      console.log('Error getting alert triggers: ', err);
    })
  }

  getUnits(){
    let units = this.supervisorService.getUnits();
    units
    .then(units => {
      this.units = units;
      // console.log('Units 2: ', this.units);
    })
    .catch(err => {
      console.log('Error getting units: ', err);
    })
  }

  getDatapoints(){
    let datapoints = this.supervisorService.getDatapoinstInfo();
    datapoints
    .then(datapoints => {
      // console.log('Datapoint 2: ', datapoints);
      this.datapoints = datapoints;
    })
    .catch(err => {
      console.log('Error getting datapoints: ', err);
    })
  }

  getAssetMeasurements(){
    let assetMeasurements = this.supervisorService.assetMeasurements();
    assetMeasurements
    .then(measurements => {
      this.assetMeasurements = measurements;
    })
    .catch(err => {
      console.log('Error getting measurements: ', err);
    })
  }
}
