import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorService } from './interceptors/interceptors.index';

import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";
import { JwtModule } from "@auth0/angular-jwt";
import { getToken } from "./utils/tokenGetter";

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    'pan': {
      direction: Hammer.DIRECTION_ALL,
    }
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AmplifyAngularModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        blacklistedRoutes: [],
        whitelistedDomains: ['api.dev.noven.io', 'api.test.noven.io', 'api.app.noven.io']
      }
    }),
  ],
  providers: [{provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig}, {provide: HTTP_INTERCEPTORS, useClass: ErrorService, multi: true}, AmplifyService],
  bootstrap: [AppComponent]
})
export class AppModule {}
