import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alertChanged = new Subject<any>();
  alertChangedObservable = this.alertChanged.asObservable();
  constructor() { }

  changeValue(value: any){
    this.alertChanged.next(value);
  }
}
