import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService, DataService } from 'src/app/services/services.index';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ErrorService implements HttpInterceptor{

  constructor(public authService: AuthService, public router: Router) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('Req: ', req);
    
    // const httpRequest = req.clone({
    //   headers: new HttpHeaders({
    //     'Cache-Control': 'no-cache',
    //     'Pragma': 'no-cache',
    //     'Expires': '0'
    //   })
    // });

    // const httpheaders = req.clone({
    //   headers: req.headers
    //   .set('Authorization', `Bearer `)
    //   .set('Cache-Control', 'no-cache')
    //   .set('Pragma', 'no-cache')
    //   .set('Expires', '0')
    // })
    
    return next.handle(req).pipe(catchError(err => {
      if([401, 403].includes(err.status)){
        this.authService.signOut()
        this.router.navigate(['/login']);
      }  
      const error = (err && err.error && err.error.message) || err.statusText;
      // console.log('Err: ', err);
      return throwError(error)
    }));

    // return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
    //   console.log('Event: ', event);
    //   if(event instanceof HttpResponse){
    //     console.log('Response: ', event);
    //   }
    // }, (err: any) => {
    //   if(err instanceof HttpErrorResponse){
    //     console.log('Error: ', err);
    //   }
    // }))

    // return next.handle(req).pipe(
    //   catchError(this.handleError)
    // )

    // return next.handle(req).pipe(
    //   catchError(
    //     err =>
    //       new Observable<HttpEvent<any>>(observer => {
    //         if (err instanceof HttpErrorResponse) {
    //           const errResp = <HttpErrorResponse>err;
    //           console.log(errResp)
    //         }
    //         observer.error(err);
    //         observer.complete();
    //       })
    //   )
    // );
    
  }

  private handleError(error: HttpErrorResponse){
    if(error.error instanceof ErrorEvent){
      console.error('An error ocurred: ', error.error.message)
    }else{
      console.error(`Backend returned code ${error.status} ` + `body was: ${error.error} `)
    }
    return throwError(`Something bad happened `)
  }
}
