import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullscreenService {
  isFullScreen = new Subject<boolean>();
  fullScreen: boolean;
  constructor() { 
    this.fullScreen = false;
    this.loadSettings();
  }

  saveSettings(){
    this.isFullScreen.next(this.fullScreen);
    localStorage.setItem('fullScreen', JSON.stringify(this.fullScreen));
  }

  loadSettings(){
    if(localStorage.getItem('fullScreen')){
      this.fullScreen = JSON.parse(localStorage.getItem('fullScreen'));
      this.applySize(this.fullScreen);
    }else{
      this.applySize(this.fullScreen);
    }
  }

  applySize(isFullScreen: boolean){
    this.fullScreen = isFullScreen;
    this.saveSettings();
  }
}
