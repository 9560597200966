import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  isDarkTheme = new Subject<boolean>();
  darkTheme: boolean;
  constructor(@Inject(DOCUMENT) private _document) { 
    this.darkTheme = false;
    this.loadSettings();
  }
  saveSettings(){
    this.isDarkTheme.next(this.darkTheme);
    localStorage.setItem('darkTheme', JSON.stringify(this.darkTheme));
  }

  loadSettings(){
    if(localStorage.getItem('darkTheme')){
      this.darkTheme = JSON.parse(localStorage.getItem('darkTheme'));
      this.applyTheme(this.darkTheme);
    }else{
      this.applyTheme(this.darkTheme);
    }
  }

  applyTheme(isDarkTheme: boolean){
    if(isDarkTheme){
      this._document.getElementById('theme').classList.add('dark-theme');
    }else{
      this._document.getElementById('theme').classList.remove('dark-theme');
    }
    this.darkTheme = isDarkTheme;
    this.saveSettings();
  }
}
