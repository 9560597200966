import { Injectable } from '@angular/core';
import { NewUser, UserCreated } from "../../interfaces/interfaces.index";
import { RequestService } from "../request/request.service";


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public requestService: RequestService) { }

  public createUser(user: any): Promise<UserCreated>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response: any = await this.requestService.get('/createuser/createuser', user);
        // console.log('User response: ', response);
        
        if (response && response.User) {
          resolve(response.User);
        } else {
          reject({
            message: `Username "${user.username}" already exist`
          });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public editUser(user: any):Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createPerson/edituser', user)
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({
            message: response.message || 'Error updating user'
          });
        }
      }catch(err){
        reject(err)
      }
    })
  } 

  public deleteUser(userId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createPerson/deleteuser', {
          userid: userId
        })
        if (response.statusCode === 200) {
          resolve(response);
        } else {
          reject({ message: response.message });
        }
      }catch(err){
        reject(err)
      }
    })
  }

  public searchUser(userId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createuser/usermanagement', {
          userId: userId,
          action: 'search'
        })
        resolve(response);
      }catch(err){
        reject(err)
      }
    })
  }

  public addUserGroup(userId: string, groups: string[]): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createuser/usermanagement', {
          userId: userId,
          groups: groups,
          action: 'addtogroup'
        })
        resolve(response)
      }catch(err){
        reject(err)
      }
    })
  }

  public removeUserGroup(userId: string, groups: string[]): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createuser/usermanagement', {
          userId: userId,
          groups: groups,
          action: 'removefromgroup'
        })
        resolve(response)
      }catch(err){
        reject(err)
      }
    })
  }

  public updateUserStatus(userId: string, status: 'active' | 'disable'): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createuser/usermanagement', {
          groups: [],
          userId: userId,
          action: status
        });
        resolve(response)
      }catch(err){
        reject(err)
      }
    })
  }

  public deleteUserGroup(userId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createuser/usermanagement', {
          groups: [],
          userId: userId,
          action: 'delete'
        })
        resolve(response)
      }catch(err){
        reject(err)
      }
    })
  }
}
