import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PressureService {

  isPressureChart = new Subject<boolean>();
  pressureChart: boolean;
  isPressureAsset = new Subject<any>();
  pressureAsset: any;
  constructor() { 
    this.pressureChart = false;
    this.loadPressureChart();
  }

  loadPressureChart(){
    if(localStorage.getItem('pressureChart')){
      this.pressureChart = JSON.parse(localStorage.getItem('pressureChart'))
      this.displayPressureChart(this.pressureChart);
    }else{
      this.displayPressureChart(this.pressureChart);
    }
  }

  displayPressureChart(isPressureChart: boolean){
    this.pressureChart = isPressureChart;
    this.saveSettings()
  }

  saveSettings(){
    this.isPressureChart.next(this.pressureChart);
    localStorage.setItem('pressureChart', JSON.stringify(this.pressureChart))
  }

  sendPressureAsset(asset: any){
    this.pressureAsset = asset;
    this.saveAsset()
  }

  saveAsset(){
    this.isPressureAsset.next(this.pressureAsset);
  }
}
