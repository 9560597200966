import 'hammerjs';
import Amplify from 'aws-amplify';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {
    Amplify.configure({
      Auth: {
        // REQUIRED - Amazon Cognito Region
        region: environment.region,
        clientId: environment.clientId,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: environment.userPoolId,
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: environment.identityPoolId,
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: environment.mandatorySignIn,
        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: environment.identityPoolRegion,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: environment.userPoolWebClientId
      }
    });
  }
}
