import { Injectable } from '@angular/core';
import { CognitoUser } from "amazon-cognito-identity-js";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Auth } from "aws-amplify";
import { get } from "lodash";
import { CompanyService } from "../company/company.service";
import { Person, Saml } from "../../interfaces/interfaces.index";
import { Router } from '@angular/router';
import { RequestService } from '../request/request.service';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private saml_name: string;
  private type_name: string;
  private profile_name: string;
  constructor(public jwtService: JwtHelperService, public companyService: CompanyService, public router: Router, public requestService: RequestService) { 
    this.saml_name = 'saml';
    this.type_name = 'login_type';
    this.profile_name = 'profile';
  }

  public signIn(username: string, password: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      Auth.signIn(username, password)
      .then((user: any)=> {
        resolve(user)
      })
      .catch((error: any)=> {
        reject(error)
      })
    })
  }

  public signInSaml(data: Saml){
    try{
      this.setLoginType('saml');
      localStorage.setItem(this.saml_name, JSON.stringify(data))
    }catch(error){
      throw error;
    }
  }

  public signOut(){
    localStorage.clear();
    Auth.signOut();
  }

  // public signOut(){
  //   let loginType = localStorage.getItem('login_type');
  //   console.log(loginType);
  //   if(loginType === 'saml'){
  //     window.location.href = `https://motus-local.auth.us-west-2.amazoncognito.com/logout?client_id=${environment.clientId}&logout_uri=http://localhost:4200`;
  //   }else{
  //     Auth.signOut();
  //   }
  //   localStorage.clear();
  // }

  public setLoginType(type: 'saml' | 'amp'){
    localStorage.setItem(this.type_name, type);
  }

  public getLoginType(): string{
    return localStorage.getItem(this.type_name);
  }

  public getProfile(): Promise<Person>{
    return new Promise(async(resolve, reject)=> {
      try{
        let profile: Person = JSON.parse(localStorage.getItem(this.profile_name));
        if(profile && profile.id){
          resolve(profile)
        }else{
          const email = await this.getEmail();
          profile = await this.companyService.getProfileByEmail(email);
          localStorage.setItem(this.profile_name, JSON.stringify(profile));
          resolve(profile)
        }
      }catch(error){
        reject(error)
      }
    })
  }

  // ************************************************************************
  // **********************     isLoggedIn     ******************************
  // ************************************************************************
  public isLoggedIn(): Promise<boolean>{
    return new Promise(async(resolve, reject)=> {
      let isLoggedIn = false;
      const loginType = this.getLoginType();
      try{
        if(loginType === 'amp'){
          isLoggedIn = await this.checkAmplifyLogin();
        }else if(loginType === 'saml'){
          isLoggedIn = await this.checkSamlLogin();
        }else{
          this.signOut();
        }
      }catch(error){
        reject(error)
      }
      resolve(isLoggedIn);
    })
  }

  public checkAmplifyLogin(): Promise<boolean>{
    return new Promise(async(resolve, reject)=> {
      Auth.currentAuthenticatedUser()
      .then(user => {
        if(user){
          resolve(true)
        }
      })
      .catch(error => {
        resolve(false)
      })
    })
  }

  public checkSamlLogin(): Promise<boolean>{
    return new Promise(async(resolve, reject)=> {
      try{
        const token = await this.jwtService.tokenGetter()
        // console.log('TOKEN: ', token);
        if(!this.jwtService.isTokenExpired(token)){
          resolve(true);
        }else{
          resolve(false);
        }
      }catch(error){
        reject(error)
      }
    })
  }
  // ************************************************************************
  // **********************     getUsername     *****************************
  // ************************************************************************
  public getUsername(): Promise<string>{
    return new Promise(async(resolve, reject)=> {
      let username = '';
      const loginType = this.getLoginType();
      try{
        if(loginType === 'amp'){
          username = await this.getUsernameAmplify()
        }else if(loginType === 'saml'){
          username = await this.getUsernameSaml()
        }
      }catch(error){
        reject(error)
      }
      resolve(username)
    })
  }

  public getUsernameAmplify(): Promise<string>{
    return new Promise(async(resolve, reject)=> {
      let username = '';
      try{
        const userInfo = await Auth.currentUserInfo();
        username = get(userInfo, 'username', '')
      }catch(error){
        reject(error)
      }
      resolve(username);
    })
  }
  
  public getUsernameSaml():Promise<string>{
    return new Promise(async(resolve, reject)=> {
      let username = '';
      try{
        const token = await this.jwtService.tokenGetter();
        const tokenData = this.jwtService.decodeToken(token);
        username = get(tokenData, 'email', '')
      }catch(error){
        reject(error)
      }
      resolve(username)
    })      
  }

  // ************************************************************************
  // ************************     getEmail     ******************************
  // ************************************************************************
  public getEmail(): Promise<string>{
    return new Promise(async(resolve, reject)=> {
      let email = '';
      let loginType = this.getLoginType();
      try{
        if(loginType === 'amp'){
          email = await this.getEmailAmplify()
        }else if(loginType === 'saml'){
          email = await this.getEmailSaml()
        }
        // console.log('Email: ', email);
      }catch(error){
        reject(error)
      }
      resolve(email);
    })
  }

  public getEmailAmplify(): Promise<string>{
    return new Promise(async(resolve, reject)=> {
      let email = '';
      try{
        const userInfo = await Auth.currentUserInfo();
        email = get(userInfo, 'attributes.email', '')
      }catch(error){
        reject(error)
      }
      resolve(email)
    })
  }

  public getEmailSaml(): Promise<string>{
    return new Promise(async(resolve, reject)=> {
      let email = '';
      try{
        const token = await this.jwtService.tokenGetter();
        const tokenData = this.jwtService.decodeToken(token);
        email = get(tokenData, 'email', '')
      }catch(error){
        reject(error)
      }
      resolve(email);
    })
  }

  // ************************************************************************
  // ************************     PERMISSIONS     ***************************
  // ************************************************************************
  public getPermissions(): Promise<string[]>{
    return new Promise(async(resolve, reject)=> {
      let permissions = [];
      let loginType = this.getLoginType();
      try{
        if(loginType === 'amp'){
          permissions = await this.getPermissionsAmplify();
          // console.log('getPermissions Amplify: ', permissions);
        }else if(loginType === 'saml'){
          permissions = await this.getPermissionsSaml();
          // console.log('getPermissions SAML: ', permissions);
        }
      }catch(error){
        reject(error)
      }
      resolve(permissions)
    })
  }

  public getPermissionsAmplify(): Promise<string[]>{
    return new Promise(async(resolve, reject)=> {
      let permissions = [];
      try{
        const userInfo = await Auth.currentAuthenticatedUser();
        permissions = get(userInfo, 'signInUserSession.accessToken.payload.cognito:groups', [])
      }catch(error){
        reject(error)
      }
      resolve(permissions);
    })
  }

  public getPermissionsSaml(): Promise<string[]>{
    return new Promise(async(resolve, reject)=> {
      let permissions = [];
      try{
        const token = await this.jwtService.tokenGetter();
        const tokenData = this.jwtService.decodeToken(token);
        // console.log('Token: ', tokenData);
        permissions = get(tokenData, 'cognito:groups', [])
      }catch(error){
        reject(error)
      }
      resolve(permissions)
    })
  }

  public hasLoginPermissions(): Promise<boolean>{
    return new Promise(async(resolve, reject)=> {
      let hasPermission = false;
      try{
        const nonAllowedPermissions = ['pumper', 'installer'];
        const permissions = await this.getPermissions();
        // console.log('hasLoginPermissions: ', permissions);
        permissions.forEach(permission => {
          if(!nonAllowedPermissions.includes(permission)){
            hasPermission = true;
          }else{
            hasPermission = false;
          }
        })
      }catch(error){
        reject(error);
      }
      resolve(hasPermission);
    })
  }

  // ************************************************************************
  // **************************     TOKEN     *******************************
  // ************************************************************************
  public getToken():Promise<string>{
    return new Promise(async(resolve, reject)=> {
      let token = '';
      let loginType = this.getLoginType();
      try{
        if(loginType === 'amp'){
          token = await this.getTokenAmplify();
        }else if(loginType === 'saml'){
          token = await this.getTokenSaml();
        }
      }catch(error){
        reject(error)
      }
      resolve(token);
    })
  }

  public getTokenAmplify(): Promise<string>{
    return new Promise(async(resolve, reject)=> {
      let token = '';
      try{
        const userInfo = await Auth.currentAuthenticatedUser();
        token = get(userInfo, 'signInUserSession.idToken.jwtToken', '')
      }catch(error){
        reject(error)
      }
      resolve(token);
    })
  }

  public getTokenSaml(): Promise<string>{
    return new Promise(async(resolve, reject)=> {
      let token = '';
      try{
        token = await this.jwtService.tokenGetter();
      }catch(error){
        reject(error)
      }
      resolve(token);
    })
  }


}
