export const environment = {
  production: true,
  region: "us-west-2",
  mandatorySignIn: false,
  identityPoolRegion: 'us-east-1',
  userPoolId: 'us-west-2_6BYibHZSe',
  clientId: '113fdaekdhnckgjcgjr61u88ul',
  apiEndpoint: 'https://api.app.noven.io',
  userPoolWebClientId: '113fdaekdhnckgjcgjr61u88ul',
  // googleApiKey: 'AIzaSyB_GgkiZFx8n3wwEyQg8JYXZus7P016vs8',
  googleApiKey: 'AIzaSyAbTaMJ7RGClG_gOu3KUABUWDFQIyWI_xY',
  identityPoolId: 'us-west-2:69400358-529a-4a80-8fed-e6339d791430',
  saml: {
    provider: "Motus-Employee",
    redirect_uri: "https://www.app.noven.io/saml",
    url: "https://noven-app.auth.us-west-2.amazoncognito.com/oauth2/authorize"
  }
};
