import { Auth } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";

function getTokenFromAmplify(): Promise<string> {
    return new Promise(async (resolve, reject) => {
        let token = "";

        try {
            const session: CognitoUserSession = await Auth.currentSession();
            token = session.getIdToken().getJwtToken();
        } catch (err) {
            console.log("Error getting amplify token", err);
        }

        resolve(token);
    });
}

function getTokenFromSaml(): string {
    let token = "";
    const data = localStorage.getItem("saml") || "{}";
    const tokenData = JSON.parse(data);

    token = tokenData.id_token || "";

    return token;
}

export function getToken(): Promise<string> {
    return new Promise(async (resolve, reject) => {
        let token = "";
        const loginType = localStorage.getItem("login_type");

        try {
            if (loginType === "amp") {
                token = await getTokenFromAmplify();
            } else if (loginType === "saml") {
                token = getTokenFromSaml();
            }
        } catch (err) {
            console.log("error token getter", err);
        }

        resolve(token);
    });
}