import { Injectable } from '@angular/core';
import { SupervisorService } from '../supervisor/supervisor.service';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(public supervisorService: SupervisorService) { }
  private readonly _alerts = new BehaviorSubject<any>({});
  readonly alerts$ = this._alerts.asObservable();

  get alerts(): any{
    return this._alerts.getValue();
  }

  set alerts(value: any){
    this._alerts.next(value);
  }

  getAlerts(assetIds: string[]){
    let alerts = this.supervisorService.getAlerts(assetIds);
    alerts
    .then(alerts => {
      // console.log('Alerts: ', alerts);

      let alertsKeys = Object.keys(alerts);
      
      if(alertsKeys.length > 0) {
        this.alerts = alerts;
      }else{
        this.alerts = {
          empty: true
        }
      }
    })
    .catch(err => {
      console.log('Error getting alerts: ', err);
    })
  }
}
