import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  public endPoint: string;
  public clientId: string;
  public redirect_uri: string;
  constructor(private http: HttpClient) { 
    this.endPoint = environment.apiEndpoint;
    this.clientId = environment.clientId;
    this.redirect_uri = environment.saml.redirect_uri;
  }

  public post(uri: string, body?: any, options?: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const subscription = this.http
        .post<any>(`${this.endPoint}${uri}`, body, options)
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);
          },
          err => {
            // console.log('Subscription: ', subscription);
            // console.log('Uri', uri)
            // console.log('Body', body);
            // console.log('Options: ', options);
            // console.log('Request: ', err)
            subscription.unsubscribe();
            reject(err);
          }
        );
    });
  }

  public get(uri: string, params?: any) {
    return new Promise((resolve, reject) => {
      const subscription = this.http
        .get<any>(`${this.endPoint}${uri}`, {
          params: params
        })
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);
          },
          err => {
            subscription.unsubscribe();
            reject(err);
          }
        );
    });
  }

  public logOut(){
    return new Promise((resolve, reject) => {
      const subscription = this.http
      .get<any>(`https://motus-local.auth.us-west-2.amazoncognito.com/saml2/logout?client_id=${this.clientId}&logout_uri=${this.redirect_uri}`, {

      })
      .subscribe(data => {
        subscription.unsubscribe();
        resolve(data);
      },
      err => {
        subscription.unsubscribe();
        reject(err);
      })
    })
  }
}
