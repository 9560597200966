import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PlotlinesService {

  plotlineChanged = new Subject<any>();
  plotlineChangedObservable = this.plotlineChanged.asObservable();
  constructor() { }

  changeValue(value: any){
    this.plotlineChanged.next(value);
  }
}
