import { Injectable } from '@angular/core';
import { RequestService } from "../request/request.service";

@Injectable({
  providedIn: 'root'
})
export class SalesmanService {

  constructor(public requestService: RequestService) { }
  
  public saveCompanyName(name: string): Promise<any>{
    return new Promise(async(resolve, reject) => {
      try{
        const response = await this.requestService.post('/createCompany/createCompany', {
          companyName: name
        });
        if (response.statusCode === 200) {
          let company = response.data;
          resolve(company);
        } else {
          let error = response;
          reject(error);
        }
      }catch(err){
        reject(err);
      }
    })
  }

  public saveCompanyData(data: any): Promise<any>{
    return new Promise(async(resolve, reject) => {
      try{
        const response = await this.requestService.post('/createPerson/createPerson', data);
        if (response.statusCode === 200) {
          const res = response.data;
          resolve(res);
        } else {
          let error = response;
          reject(error);
        }
      }catch(err){
        reject(err);
      }
    })
  }
}
