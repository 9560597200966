import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Role } from "../../interfaces/interfaces.index";
import { get } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  public getRoles(): Array<Role> {
    return [
      {
        name: 'Administrator',
        value: 'administrator'
      },
      {
        name: 'Installer',
        value: 'installer'
      },
      {
        name: 'Noven Admin',
        value: 'novenadmin'
      },
      {
        name: 'Pumper',
        value: 'pumper'
      },
      {
        name: 'Salesman',
        value: 'salesman'
      },
      {
        name: 'Supervisor',
        value: 'supervisor'
      }
    ];
  }

  public searchAddressByPostalCode(postalCode: string): Promise<{city: string;state: string;country: string;}> {
    return new Promise((resolve, reject) => {
      const subscription = this.http
        .get<any>('https://maps.googleapis.com/maps/api/geocode/json', {
          params: {
            address: postalCode,
            key: environment.googleApiKey
          }
        })
        .subscribe(
          data => {
            const address = {
              city: '',
              state: '',
              country: ''
            };
            subscription.unsubscribe();
            const results: Array<any> = get(data, 'results[0].address_components', []);
            if (results.length > 0) {
              const city =
                results.find(result => {
                  const type = get(result, 'types[0]', '');
                  return type === 'locality';
                }) || {};
              const state =
                results.find(result => {
                  const type = get(result, 'types[0]', '');
                  return type === 'administrative_area_level_1';
                }) || {};
              const country =
                results.find(result => {
                  const type = get(result, 'types[0]', '');
                  return type === 'country';
                }) || {};
              address.city = city.long_name || '';
              address.state = state.long_name || '';
              address.country = country.long_name || '';
            }
            resolve(address);
          },
          err => {
            subscription.unsubscribe();
            reject(err);
          }
        );
    });
  }

  public getTimezone(location: string, timestamp: string): Promise<string>{
    return new Promise((resolve, reject) => {
      const subscription = this.http
      .get<any>('https://maps.googleapis.com/maps/api/timezone/json', {
        params: {
          location: location,
          timestamp: timestamp,
          key: environment.googleApiKey
        }
      })
      .subscribe(data => {
        subscription.unsubscribe();
        let timeZoneId = data.timeZoneId;
        resolve(timeZoneId)
      },
      err => {
        subscription.unsubscribe();
            reject(err);
      })
    })
  }
}
