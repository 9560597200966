import { Injectable } from '@angular/core';
import { Battery, Well, Tank, User, Pumper, WellCreated, Person, PumperNote, Contact, Company } from "../../interfaces/interfaces.index";
import { RequestService } from "../request/request.service";
import { get } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private requestService: RequestService) { }

  public getProfileByEmail(email: string): Promise<Person>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/checkPerson/checkperson', {
          email  
        });
        if(response.statusCode === 200){
          resolve(response.data)
        }else{
          reject({message: response.message})
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public getCompanyIdByEmail(email: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/checkPerson/checkperson', {
          email
        });
        if (response.statusCode === 200) {
          resolve(response.data.owner);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public getProfileByCompanyId(companyId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addWell/getWell', {
          wellid: companyId
        })
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public getUsersByCompany(companyId: string): Promise<User[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.requestService.post('/createPerson/users', { owner: companyId });
        if (response.statusCode === 200) {
          resolve(response.data || []);
        } else {
          reject({ message: response.message });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  public saveProfile(companyData: any): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/createCompany/updateCompany', companyData);
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public getAssets(companyId: string): Promise<{
    tanks: {[key: string]: Tank};
    wells: {[key: string]: WellCreated};
    tankBatteries: {[key: string]: Battery}
  }>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/listassest/listassest',{
          ownerid: companyId
        })
        if (response.statusCode === 200) {
          resolve(response.assets);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  allWells(companyId: string): Promise<any>{
    return new Promise((resolve, reject)=>{
      const response: any = this.requestService.post('/assests/listWells', {
        ownerid: companyId
      })
      .then(response => {
        // console.log('Response1: ', response);
        if(response.statusCode === 200){
          resolve(response.assets)
        }else{
          reject({message: response.message})
        }
      })
      .catch(err =>{
        console.log('Error getting wells: ', err);
      })
    }) 
  }

  allTanks(companyId: string): Promise<any>{
    return new Promise((resolve, reject)=>{
      const response: any = this.requestService.post('/assests/listTanks', {
        ownerid: companyId
      })
      .then(response => {
        // console.log('Response2: ', response);
        if(response.statusCode === 200){
          resolve(response.assets)
        }else{
          reject({message: response.message})
        }
      })
      .catch(err => {
        console.log('Error getting tanks: ', err);
      })
    }) 
  }

  allLocations(companyId: string): Promise<any>{
    return new Promise((resolve, reject)=> {
      const response: any = this.requestService.post('/assests/listTankBatteries', {
        ownerid: companyId
      })
      .then(response => {
        // console.log('Response2: ', response);
        if(response.statusCode === 200){
          resolve(response.assets)
        }else{
          reject({message: response.message})
        }
      })
      .catch(err => {
        console.log('Error getting locations: ', err);
      })
    })
  }

  public getAssetsWells(companyId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/listassest/listwells', {
          ownerid: companyId
        })
        // console.log('Response: ', response);
        if (response.statusCode === 200) {
          resolve(response.assets);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error);
      }
    })
  }

  public getAssetsTanks(companyId: string): Promise<any>{
    return new Promise (async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/listassest/listtank', {
          ownerid: companyId
        })
        // console.log('Response: ', response);
        if (response.statusCode === 200) {
          resolve(response.assets);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public getWellData(apiNumber: string): Promise<WellCreated>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addWell/staticWellDetail',{
          apiNumber: apiNumber
        })
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message : response.message});
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public getCompanies(): Promise<Company[]>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/customersupportservice/companyList')
        if(response.statusCode === 200){
          resolve(response.date);
        }else{
          reject({message : response.message});
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public changeCompany(companyId: string): Promise<any>{
    return new Promise (async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/customersupportservice/customersupportservice',{
          id: companyId
        })
        if(response.statusCode === 200){
          resolve(response.date);
        }else{
          reject({message : response.message});
        }
      }catch(error){
        reject(error)
      }
    })
  }

  // ************************************************************************
  // ***********************     BATTERY     ********************************
  // ************************************************************************
  public getBatteries(id: string, type: 'wellid' | 'companyid'): Promise<Battery[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {};
        data[type] = id;
        const response = await this.requestService.post('/addBattries/listBattries', data);
        if (response.statusCode === 200) {
          let batteries = [];
          if (Array.isArray(response.data)) {
            batteries = response.data.filter(element => {
              const id = get(element, 'id', '');
              return id.includes('TANKBATTERY');
            });
          }
          resolve(batteries);
        } else {
          reject({ message: response.message });
        }
      } catch (err) {
        reject(err);
      }
    });
  }


  public addBattery(battery: Battery): Promise<Battery>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addBattries/addBattries', battery);
        if (response.statusCode === 200) {
          resolve(response.Battries);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public updateBattery(battery: Battery): Promise<Battery>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addBattries/updateBattries', battery);
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public deleteBattery(batteryId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addBattries/deleteBattries', {
          batteryid: batteryId
        })
        if (response.statusCode === 200) {
          resolve(response);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  // ************************************************************************
  // ************************     WELLS     *********************************
  // ************************************************************************
  public getWells(companyId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/assests/listWells',{
          owner: companyId
        })
        if (response.statusCode === 200) {
          resolve(response.assets);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public addWell(well: Well): Promise<WellCreated>{
    return new Promise (async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addWell/addWell', well);
        if (response.statusCode === 200) {
          resolve(response);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public updateWell(well: Well): Promise<WellCreated>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addWell/updateWell', well);
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public deleteWell(wellId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addWell/deleteWell', {
          wellid: wellId
        })
        if (response.statusCode === 200) {
          resolve(response);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  // ************************************************************************
  // ************************     TANKS     *********************************
  // ************************************************************************
  public getTanks(id: string, type: 'wellid' | 'companyid'): Promise<Tank[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {};
        data[type] = id;
        const response = await this.requestService.post('/listTank/listTank', data);
        if (response.statusCode === 200) {
          let tanks = [];
          if (Array.isArray(response.data)) {
            tanks = response.data.filter(element => {
              const id = get(element, 'id', '');
              return id.includes('TANK-');
            });
          }
          resolve(tanks);
        } else {
          reject({ message: response.message });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  public addTank(tank: Tank): Promise<Tank>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addTank/addTank', tank);
        if (response.statusCode === 200) {
          resolve(response.tank);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public updateTank(tank: Tank): Promise<Tank>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addTank/updateTank', tank);
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public deleteTank(tankId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addTank/deleteTank', {
          tankid: tankId
        })
        if (response.statusCode === 200) {
          resolve(response);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  // ************************************************************************
  // **********************       CONTACTS     ******************************
  // ************************************************************************
  public getContacts(companyId: string): Promise<Contact[]>{
    return new Promise(async(resolve, reject)=> {
      try{
        // const response = await this.requestService.post('/listContact/listContact', {
        const response = await this.requestService.post('/contacts/listContact', {
          companyId: companyId
        })
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message : response.message});
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public addContact(contact: Contact): Promise<Contact>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/contacts/addContact', contact);
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message : response.message});
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public updateContact(contact: Contact): Promise<Contact>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/contacts/updateContact', contact)
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message : response.message});
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public deleteContact(contactId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/contacts/deleteContact', {
          id: contactId
        })
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message : response.message});
        }
      }catch(error){
        reject(error)
      }
    })
  }

  // ************************************************************************
  // *************************       NOTES     ******************************
  // ************************************************************************
  public getNotes(wellId: string): Promise<PumperNote[]>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addpumpernotes/getpumpernotes', {
          wellid: wellId
        })
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }
  
  public addNote(note: PumperNote): Promise<PumperNote>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addpumpernotes/addpumpernotes', note)
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  // ************************************************************************
  // *************************     PUMPERS     ******************************
  // ************************************************************************
  public getPumpers(wellId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/addWell/listPumpers', {
          owner: wellId
        })
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public addPumper(pumper: Pumper): Promise<Pumper>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/assets/addAssetToPumper', pumper);
        if (response.statusCode === 200) {
          resolve(response.data);
        } else {
          reject({ message: response.message });
        }
      }catch(error){
        reject(error)
      }
    })
  }

  // ************************************************************************
  // ***********************     ASSIGN WELLS     ***************************
  // ************************************************************************
  public getAssignedWells(pumperId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/assets/getassignwellsforpumper', {userid: pumperId})
        // console.log('Response: ', response);
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message : response.message});
        }
      }catch(error){
        reject(error)
      }
    })
  }

  public deleteAssignedWell(assetId: string): Promise<any>{
    return new Promise(async(resolve, reject)=> {
      try{
        const response = await this.requestService.post('/assets/deleteassignassetsforpumper', {assetid: assetId})
        if(response.statusCode === 200){
          resolve(response.data);
        }else{
          reject({message : response.message});
        }
      }catch(error){
        reject(error)
      }
    })
  }
}
